<template>
  <ErrorPage variant="403" />
</template>

<script>
import ErrorPage from "@/components/layout/ErrorPage.vue";

/**
 * Affiche la page 403.
 */
export default {
  name: "Forbidden",
  components: {
    ErrorPage,
  },
};
</script>
